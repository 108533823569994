<template>
   <section class="cont">
      <!-- 面包屑 -->
      <el-row class="crumbs-box">
         <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>业务中台</el-breadcrumb-item>
            <el-breadcrumb-item>预售券</el-breadcrumb-item>
            <el-breadcrumb-item>预售券列表</el-breadcrumb-item>
            <el-breadcrumb-item>添加预售券</el-breadcrumb-item>
         </el-breadcrumb>
      </el-row>
      <el-row class="content-box">
         <el-row class="pre-sale-main">
            <el-row><img style="width: 390px;" src="@/assets/Presalebackground.jpg" alt=""></el-row>
            <el-row class="right-form">
               <el-row class="tit">基本信息</el-row>
                 <el-form ref="formData" :model="formData" :rules="rules" label-width="110px">
                   <el-form-item label="预售券名称" prop="mainTitle">
                     <el-input v-model="formData.mainTitle" ></el-input>
                   </el-form-item>

                   <el-form-item label="预售券副标题" prop="subTitle">
                     <el-input v-model="formData.subTitle" ></el-input>
                   </el-form-item>

                   <el-form-item label="预售商品">
                     <el-button type="primary" class="bg-gradient" @click="gettype('ROOM_TYPE')">选择房型</el-button>
                     <el-button type="primary" class="bg-gradient" @click="gettype('PRODUCT')">选择商品</el-button>
   <!--                  <div class="img-box" v-if="imgboxshow" style="display: flex;margin-bottom: 20px">-->
   <!--                    <div class="img-list" v-for="(item,index) in formData.contents" :key="index" style="position: relative;margin: 10px;">-->
   <!--                      <img :src="item.imageUrl"  />-->
   <!--                        <input class="textinpt"   v-model="item.remark"  />-->
   <!--                      <div class="delicon" @click="deleteimg(item,index)">×</div>-->
   <!--                    </div>-->
   <!--                    </div>-->
   <!--                  <el-upload-->
   <!--                      class="avatar-uploader"-->
   <!--                      action=""-->
   <!--                      :limit="5"-->
   <!--                      ref="upload"-->
   <!--                      :show-file-list="false"-->
   <!--                      :on-change="handleChange"-->
   <!--                      :auto-upload="false"-->
   <!--                      :multiple="false"-->
   <!--                      style="z-index: 999"-->
   <!--                  >-->
   <!--                    <i  class="el-icon-plus avatar-uploader-icon"></i>-->
   <!--                  </el-upload>-->
                   </el-form-item>

                   <el-form-item label=""  v-if="chooseshow">
                     <div class="table-box">
                       <div class="table-item">名称</div>
                       <div class="table-item">单价</div>
                       <div class="table-item">{{formData.couponPresellProduct.type =='ROOM_TYPE'?'房晚':'数量'}}</div>
                     </div>
                     <!--编辑时-->
                     <div class="table-box">
                         <div class="table-item" >{{formData.couponPresellProduct.type =='ROOM_TYPE'?roominfo.roomTypeName:roominfo.title}}</div>
                         <div class="table-item" style="color: red">¥{{formData.couponPresellProduct.type =='ROOM_TYPE'?roominfo.roomTypePrice ||roominfo.weekdayPrice :roominfo.price}}</div>
                       <div class="table-item">
                         <el-input-number @change="changequantity" v-model="formData.couponPresellProduct.quantity"  :min="1"  :label="formData.couponPresellProduct.type =='ROOM_TYPE'?'天':'个'"></el-input-number>
                       </div>
                     </div>
                   </el-form-item>

                   <el-form-item label="预售价格" prop="presellPrice">
                     <el-input class="width-70" v-model="formData.presellPrice" @change="changepresellPrice(formData.presellPrice,'presellPrice')"  :disabled="giftBool"></el-input> 元
                   </el-form-item>

                   <el-form-item label="分销价格" v-if="false">
                      <div style="display: flex">
                         <el-form-item label="一级：" label-width="70px" prop="onePrice">
                            <el-input class="width-100" v-model="formData.onePrice" ></el-input> 元
                         </el-form-item>

                         <el-form-item label="二级：" label-width="100px" prop="twoPrice">
                            <el-input class="width-100" v-model="formData.twoPrice" ></el-input> 元
                         </el-form-item>

                         <el-form-item label="三级：" label-width="100px" prop="threePrice">
                            <el-input class="width-100" v-model="formData.threePrice" ></el-input> 元
                         </el-form-item>
                      </div>
                   </el-form-item>

                   <el-form-item label="商品原价" prop="marketPrice">
                     <el-input class="width-70" v-model="formData.marketPrice" @change="changepresellPrice(formData.marketPrice,'marketPrice')" :disabled="giftBool"></el-input> 元
                   </el-form-item>

                   <el-form-item label="库存量"  prop="issueCount">
                     <el-input class="width-70" v-model="formData.issueCount" ></el-input> 张
                   </el-form-item>

                   <el-form-item label="单用户限购数" prop="limitQuantity">
                     <el-input class="width-70" v-model="formData.limitQuantity" @change="limitQuantity()" ></el-input> 张
                   </el-form-item>

                   <el-form-item label="活动时间"  prop="activityEndTime">

                      <el-date-picker
                            v-model="activityTime"
                            type="datetimerange"
                            range-separator="至"
                            @change="choosetime()"
                            :picker-options="pickerOptions"
                            value-format="yyyy-MM-dd HH:mm:ss"
                            :default-time="['00:00:01', '23:59:59']"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期">
                      </el-date-picker>

                   </el-form-item>

                   <el-form-item label="积分兑换"  prop="isIntegral">
                     <el-radio class="radio" v-model="formData.isIntegral" label="0">不可积分兑换</el-radio>
                     <el-radio v-model="formData.isIntegral" label="1">可用积分兑换</el-radio>
                   </el-form-item>

                   <el-form-item label="有效期" prop="validType">
                     <el-radio v-model="formData.validType" label="INTERVAL_DATE" @change="changevalidType">
                       在
                       <el-date-picker
                           v-model="validDate"
                           class="m-right-10"
                           @change="choosevalidtime()"
                           :picker-options="pickerOptions"
                           type="daterange"
                           range-separator="至"
                           start-placeholder="开始日期"
                           end-placeholder="结束日期"
                           value-format="yyyy-MM-dd">
                       </el-date-picker>
                       有效
                     </el-radio>

                     <el-radio v-model="formData.validType" label="APPOINT_DATE">
                       截止到
                       <el-date-picker
                           v-if="formData.validType == 'APPOINT_DATE' "
                           v-model="formData.validDate"
                           type="date"
                           :picker-options="pickerOptions"

                           value-format="yyyy-MM-dd"
                           placeholder="选择日期">
                       </el-date-picker>
                       有效
                     </el-radio>

                     <el-radio v-model="formData.validType" label="RECEIVE_DATE">
                       购买<el-input v-if="formData.validType == 'RECEIVE_DATE' " class="width-70 m-left-right" v-model="formData.expiraDays" ></el-input>天有效
                     </el-radio>
                   </el-form-item>

                   <el-form-item label="过期退款方式" prop="refundType">
                      <el-radio v-model="formData.refundType" label="REFUSE">不可退款</el-radio>
                      <el-radio v-model="formData.refundType" label="AUTOMATIC">自动退款</el-radio>
                     <el-radio v-model="formData.refundType" label="MANUAL">手动退款</el-radio>
                   </el-form-item>

                   <el-form-item label="使用方式" prop="refundType">
                      <el-radio-group v-model="formData.useType" @change="useTypeChange">
                         <el-radio label="ONLINE">线上预约</el-radio>
                         <el-radio label="OFFLINE">线下使用</el-radio>
                         <el-radio label="ONLINE_OFFLINE">线上线下都可使用</el-radio>
                         <el-radio label="GIFT">赠品专用</el-radio>
                      </el-radio-group>
                   </el-form-item>
                   <el-form-item label="海报封面" prop="coverAttachment">
                     <div class="img-box" v-if="postershow" style="display: flex;margin-bottom: 20px">
                       <div class="poster-box">
                         <img class="poster" :src="formData.coverAttachment.poster" />
                       </div>
                     </div>
                     <el-upload
                         class="avatar-uploader"
                         action=""
                         :limit="1"
                         ref="posterupload"
                         :show-file-list="false"
                         :on-change="handleChangeposter"
                         :auto-upload="false"
                         :multiple="false"
                         style="z-index: 999"
                     >
                       <i  class="el-icon-plus avatar-uploader-icon"></i>
                     </el-upload>
                   </el-form-item>

                   <el-form-item label="标签">
                     <el-tag
                         :key="tag"
                         v-for="tag in dynamicTags"
                         closable
                         :disable-transitions="false"
                         @close="handleClose(tag)">
                       {{tag}}
                     </el-tag>
                     <el-input
                         class="input-new-tag"
                         v-if="inputVisible"
                         v-model="inputValue"
                         ref="saveTagInput"

                         @keyup.enter.native="handleInputConfirm"
                         @blur="handleInputConfirm"
                     >
                     </el-input>
                     <el-button v-else class="button-new-tag"  @click="showInput">+ 添加标签</el-button>
                   </el-form-item>

                   <el-form-item label="预售券简介" >
                     <el-input class="width-300" v-model="formData.remark" type="textarea"></el-input>
                   </el-form-item>

                   <el-form-item label="" >
                     <el-button class="width-120 bg-gradient"  type="primary" @click="submit()">提交</el-button>
                   </el-form-item>

                 </el-form>

            </el-row>
         </el-row>
        <el-dialog
            title="选择房型"
            :visible.sync="dialogVisible"
            width="50%"
            >
          <p style="font-size: 24px;padding-bottom: 20px;">预售房型</p>

          <el-table :data="tabledata" highlight-current-rowref="orderTable" tooltip-effect="light" style="height: 500px;overflow-y: scroll;">
            <el-table-column label="选择" width="200" >
              <template slot-scope="scope">
                <el-radio :label="scope.$index+1"  v-model="radio" @change.native="getCurrentRow(scope.$index+1,scope.row)"></el-radio>
              </template>
            </el-table-column>

            <el-table-column center prop="roomTotalNum" label="封面" >
                <template slot-scope="scope">
                  <img style="width: 100px;height: 60px;border-radius: 5px" v-if="formData.couponPresellProduct.type =='ROOM_TYPE' "  :src="scope.row.pmsRoomTypeImages[0] && scope.row.pmsRoomTypeImages[0].imageUrl" />
                  <img style="width: 100px;height: 60px;border-radius: 5px" v-else  :src="scope.row.img" />
                </template>
            </el-table-column>
            <el-table-column  :label="formData.couponPresellProduct.type =='ROOM_TYPE'?'名称':'商品名称' " >
              <template slot-scope="scope">
                {{ formData.couponPresellProduct.type == 'ROOM_TYPE' ? scope.row.roomTypeName : scope.row.title }}
              </template>

            </el-table-column>
            <el-table-column :label="formData.couponPresellProduct.type =='ROOM_TYPE'?'房价':'价格' " >
              <template slot-scope="scope">
                <div v-if="scope.row.roomTypePrice || scope.row.price">
                  {{ formData.couponPresellProduct.type =='ROOM_TYPE'?scope.row.roomTypePrice:scope.row.price }}
                </div>
                <div v-else>
                  <p>{{scope.row.weekdayPrice}}</p>
                  <!-- <p>周末：{{scope.row.weekendPrice}}</p> -->
                </div>
              </template>
            </el-table-column>
            <el-table-column key="5" v-if="formData.couponPresellProduct.type !='ROOM_TYPE'" label="库存" >
              <template slot-scope="scope">
                {{ scope.row.quantity }}
              </template>
            </el-table-column>
          </el-table>
          <div class="dialog-bottombox">
       <el-button @click="dialogVisible = false">取 消</el-button>
       <el-button class="bg-gradient" type="primary"  @click="determine">确 定</el-button>
     </div>
        </el-dialog>
      </el-row>
   </section>
</template>

<script>
import {urlObj} from "@/api/interface";
import {preSale} from "@/api/interface/business";
import {pms} from "@/api/interface/pms";
  import { dateFactory } from "@/common/js/common";
  import { mapState } from 'vuex'
  export default {
    watch: {
      hotelInfo: {
          handler(newVal, oldVal) {
            if (newVal.id !== oldVal.id && oldVal) {
               this.formData.hotelId = this.hotelInfo.id
               this.hotelId =  this.hotelInfo.id
              this.getfroollist()
            }
          }
      },

      'roominfo':'changequantity'
    },
    computed: {
      ...mapState(['hotelInfo'])
    },
    data(){
       let validPresellPrice = (rule, value, callback) => {
          let title = ''
          if(rule.fullField === "presellPrice") title = '预售价格'
          else title = '商品原价'
          if (value === '') {
             callback(new Error('请输入'+title))
          }if(this.formData.useType !== "GIFT"  && !value ){
             callback(new Error(title+'不能为0'))
          } else{
             callback()
          }
       }
        return {
           giftBool:false,
          dynamicTags:[],//标签列表
          inputVisible:false,
          imgboxshow:false,
          postershow:false,
          inputValue: '',
          chooseshow:false,
          roominfo:'',
          froollist:[],//房型列表
          goodslist:[],//商品列表
          tabledata:[],
          dialogVisible:false,
          radio:false,
          hotelId:'',
          validDate:[],//有效期区间默认值
          activityTime:[],//时间默认值
          pickerOptions: {
            disabledDate(time) {
              //如果没有后面的-8.64e7就是不可以选择今天的
              return time.getTime() < Date.now() -1 * 24 * 3600 * 1000;
            },
          },
          editlist:[],//接收修改时传过来的list
          roomTypeSource:'',//房型来源
          formData:{
            hotelId:'',//酒店id
            companyId:'',//渠道id
            mainTitle:"",//标题
            expiraDate:'',//有效期结束时间
            subTitle:"",//副标题
            couponPresellProduct:{
              roomTypeId:'',
              quantity:1,
              type:'',
              productId:''
            },
            status:"PROCESSING",//券类型
            // contents:[],//图片list
            coverAttachment: {'poster':'', ossImageUrl: ''},
            presellPrice:'',//预售价格
            marketPrice:'',//商品原价
            onePrice:'',//一级价格
            twoPrice:'',//二级价格
            threePrice:'',//三级价格
            issueCount:'',//库存
            limitQuantity:3,//可购买数量
            activityBeginTime:"",//活动开始时间
            activityEndTime:"",//活动结束时间
            isIntegral:"0",//积分兑换方式
            validType:"INTERVAL_DATE",//有效期类型
            validDate:"",//有效期时间
            expiraDays:"",//
            refundType:"REFUSE",//退款方式
            useType: "ONLINE",//使用方式
            tag:"",//标签内容
            remark:"",//备注
          },
          //校验
          rules: {
            mainTitle: [
              { required: true, message: '请输入预售券名称', trigger: 'blur' },
            ],
            subTitle: [
              { required: true, message: '请输入预售券副标题', trigger: 'blur' }
            ],
             presellPrice: [
               {required: true, validator: validPresellPrice, trigger: 'change'}
             ],
            marketPrice: [
               {required: true, validator: validPresellPrice, trigger: 'change'}
            ],
            onePrice: [
              { required: true, message: '请输入一级价格', trigger: 'blur' }
            ],
            twoPrice: [
              { required: true, message: '请输入二级价格', trigger: 'blur' }
            ],
            threePrice: [
              { required: true, message: '请输入三级价格', trigger: 'blur' }
            ],
            issueCount:[
              { required: true, message: '请输入库存', trigger: 'blur' }
            ],
            limitQuantity:[
              { required: true, message: '请输入单用户限购数', trigger: 'blur' }
            ],
            activityEndTime: [
              {  required: true, message: '请选择时间', trigger: 'change' }
            ],
            // validType:[
            //   { required: true, message: '请上传图片且输入图片说明', trigger: 'blur' }
            // ],
             coverAttachment:[
              { required: true, message: '请上传海报', trigger: 'blur' }
            ]
          }
        }
    },
    created () {
      //修改list键
      if (this.$route.query.command != undefined){
        let list = JSON.parse(this.$route.query.command)
        console.log(list)
        let onlist = []
        onlist[0] = list[0]
        this.formData = onlist[0]
        this.formData.onePrice = this.formData.onePrice/100
        this.formData.twoPrice = this.formData.twoPrice/100
        this.formData.threePrice = this.formData.threePrice/100

        this.formData.presellPrice = this.formData.presellPrice/100
        this.formData.marketPrice = this.formData.marketPrice/100
        if (this.formData.isIntegral == 'NO'){
          this.formData.isIntegral = '0'
        }else {
          this.formData.isIntegral = '1'
        }
        if(this.formData.tag.length != 0){
          this.dynamicTags =  this.formData.tag.split(',');

        }
            onlist.forEach((item)=>{
          let obj = {};
          for(let i=0; i <= onlist.length; i++){
            // obj.contents = JSON.parse(item.content) ;
            obj.coverAttachment = JSON.parse(item.coverPath);
            // obj.contents.forEach(item=>{
            //   item.imageUrl = window.getResourcesHost()+item.location+'/'+item.formatName
            // })
            obj.coverAttachment.poster = window.getResourcesHost()+obj.coverAttachment.location+'/'+obj.coverAttachment.formatName;
            obj.coverAttachment.ossImageUrl = obj.coverAttachment.url
          }
          this.editlist = obj
        });
        // this.formData.contents = this.editlist.contents
        this.formData.coverAttachment = this.editlist.coverAttachment
        this.activityTime = [this.formData.activityBeginTime,this.formData.activityEndTime]
        this.validDate = [this.formData.validDate,this.formData.expiraDate]
        this.roominfo = this.formData.couponPresellProduct
        this.roominfo.roomTypeName = this.formData.couponPresellProduct.productName
        this.roominfo.title = this.formData.couponPresellProduct.productName
        this.roominfo.roomTypePrice  = this.formData.couponPresellProduct.price
        this.imgboxshow = true
        this.postershow = true
        this.chooseshow = true
        console.log(this.formData)

      }
    },
    mounted() {
      this.formData.hotelId   = this.hotelInfo.id
      this.formData.companyId = JSON.parse(sessionStorage.getItem('hotelInfo') || '{}').storeId || ''
      this.getfroollist()
      this.getgoodslist()
    },

    methods: {
        //删除图片
        // deleteimg(item,i) {
        //   const url = urlObj.deleteUpload;
        //   let param = {
        //     ids:item.id
        //   };
        //   this.$axios.post(url, param).then((res) => {
        //           if (res.status == 200){
        //             this.$message({
        //               message: "图片删除成功!",
        //               type: "success",
        //             });
        //           }
        //     this.imgboxshow = false
        //     this.formData.contents.splice(i,1)
        //     if (this.formData.contents.length == 0){
        //       this.imgboxshow = false
        //     }
        //     this.imgboxshow = true
        //   });
        // },
       //
       // handleCurrentChange(val){
       //   if (val && val.Enable == 1) {
       //     this.currentRow = val;
       //     let index = this.tableData.findIndex(
       //         item => item.userUuid == this.currentRow.userUuid
       //     )
       //     if (index > -1) {
       //       this.radio = index;
       //     }
       //   }
       // },
       //限制单用户限购数最大购买数量为99
       limitQuantity(){
          if(this.formData.limitQuantity > 99){
             this.$message.error('不能超过99张')
             this.formData.limitQuantity = ''
          }

       },
       //改变预售价时
       changepresellPrice(val,type){
         if (Number(val)  <=0){
           if (type == 'marketPrice'){
             this.formData.marketPrice = ''
           }else{
             this.formData.presellPrice = ''
           }
           this.$message.error('价格必须大于0')
         }
          if(this.formData.onePrice == ''){
             this.formData.onePrice = this.formData.presellPrice
          }
          if(this.formData.twoPrice == ''){
             this.formData.twoPrice = this.formData.presellPrice
          }
         if(this.formData.threePrice == ''){
            this.formData.threePrice = this.formData.presellPrice
         }
       },
       //切换不同有效期
       changevalidType(){
          this.validDate = []
          this.formData.expiraDays = ''
       },
       //原价等于预售单价*个数
       changequantity(){
         if(this.formData.couponPresellProduct.type == 'ROOM_TYPE'){
           this.formData.marketPrice =  (this.roominfo.roomTypePrice||this.roominfo.weekdayPrice) * this.formData.couponPresellProduct.quantity
         }else{
           this.formData.marketPrice =  this.roominfo.price * this.formData.couponPresellProduct.quantity
         }
       },
       //表格单选框
       getCurrentRow(index,val) {
         this.radio = index
         this.roominfo = val
         console.log(val)
         if(this.formData.couponPresellProduct.type == 'ROOM_TYPE'){
           this.formData.couponPresellProduct.roomTypeId = val.id
           this.formData.couponPresellProduct.productId = ''
           this.formData.couponPresellProduct.productName = val.roomTypeName

         }else{
           this.formData.couponPresellProduct.productId = val.id
           this.formData.couponPresellProduct.roomTypeId = ''
           this.formData.couponPresellProduct.productName = val.title

         }
       },


       //关闭弹窗
       determine(){
          if (this.roominfo == ''){
             this.$message.error('请选择关联内容')
          }else{
             this.chooseshow = true
             this.dialogVisible = false
          }
       },

       //获取商品类型
       gettype(type){
         if(this.formData.couponPresellProduct.type != type){
           this.radio = false
           this.chooseshow = false
         }
         this.formData.couponPresellProduct.type = type
         if (type == 'ROOM_TYPE' ){
           this.tabledata = this.froollist
         }else{
           this.tabledata = this.goodslist
         }
         this.dialogVisible = true
       },

       //选择有效期区间
       choosevalidtime(){
         this.formData.validDate = this.validDate[0]
         this.formData.expiraDate = this.validDate[1]
       },

       //获取房型列表
       getfroollist() {
        //  2022/04/15 修改为查询全部房型，不区分房型来源；
          // if(this.hotelInfo.isEbooking == 'YES'){
          //    this.roomTypeSource = 'EBOOKING'
          // }else{
          //    this.roomTypeSource = 'PMS'
          // }
         const url = pms.pmsRoomTypes
         const param = {
           hotelId: this.formData.hotelId ,
          //  roomTypeSource:this.roomTypeSource,
         }
         this.$axios.post(url, param, 'json').then(res => {
           if (res.success) {
             this.froollist = res.records
             this.froollist.map(item=>{
               item.editshow = false
             })
           }
         })
       },
       //获取商品列表
          getgoodslist() {
            const url = urlObj.getGoodsPaging+ `?limit=9999&page=1`
            const param = {
              hotelId: this.formData.hotelId ,
            }
            this.$axios.post(url ,param,'json').then(res => {
              if (res.success) {
                this.goodslist = res.records
                this.goodslist.map(item=>{
                  item.img = window.getResourcesHost() + item.coverPath
                  item.editshow = false
                })
              }
            })
          },

          useTypeChange(val){
             if(val === "GIFT") {
                this.formData.presellPrice = 0
                this.formData.marketPrice = 0
                this.giftBool = true
             }else{
                this.giftBool = false
                if(this.formData.couponPresellProduct.type == 'ROOM_TYPE'){
                   this.formData.marketPrice =  Number(this.roominfo.roomTypePrice * this.formData.couponPresellProduct.quantity) || ''
                }else{
                   this.formData.marketPrice =   Number(this.roominfo.price * this.formData.couponPresellProduct.quantity) || ''
                }
                if(!this.formData.presellPrice) this.formData.presellPrice = ''
             }
          },
        //提交
        submit(){
          if (this.$route.query.command){
             let list = JSON.parse(this.$route.query.command)
             if (list[1] == 'copy'){
                this.formData.id = ''
             }
          }
           if (this.roominfo == ''){
              this.$message.error('请选择预售商品')
              return
           }
           if (this.formData.coverAttachment.poster == ''){
              this.$message.error('请上传海报封面')
              return
           }
          this.$refs['formData'].validate((valid) => {
            if (valid) {
               this.formData.onePrice = this.formData.onePrice*100
               this.formData.twoPrice = this.formData.twoPrice*100
               this.formData.threePrice = this.formData.threePrice*100

               this.formData.presellPrice = this.formData.presellPrice*100
               this.formData.marketPrice = this.formData.marketPrice*100
               this.formData.tag = this.dynamicTags.join()
              let param = this.formData
              const url = preSale.savePreSale;
                this.$axios.post(url, param, 'json').then((res) => {
                  if (res.status == 200) {
                    this.$message({
                      message: "创建成功!",
                      type: "success",
                    });
                    this.$router.push("/pre_sale_list");
                  }
                });
            } else {
              console.log('error submit!!');
              return false;
            }
          });

        },
       //图片上传
       // handleChange(file) {
       //   this.file = file.raw
       //   const url = urlObj.uploadDetailFile;
       //   let param = new FormData();
       //   param.append("file", this.file); // 文件
       //   param.append('bizId','default')
       //   param.append('module','event')
       //   param.append('menu','distributor')
       //   param.append('func','image')
       //   param.append('category','image')
       //   param.append('isThumb',true)
       //   this.$axios.post_query(url, param, null, { "Content-Type": "multipart/form-data" })
       //       .then((res) => {
       //         this.imgboxshow = false
       //         this.formData.contents.push(res.records)
       //         // console.log(this.formData.contents)
       //         this.formData.contents.forEach(item=>{
       //           item.imageUrl = window.getResourcesHost()+item.location+'/'+item.formatName
       //         })
       //         this.imgboxshow = true
       //         // console.log(this.formData);
       //
       //       });
       // },
       //选择时间后
       choosetime(){
         this.formData.activityBeginTime = this.activityTime[0]
         this.formData.activityEndTime = this.activityTime[1]

       },

       //上传海报
       handleChangeposter(file){
         this.$refs.posterupload.clearFiles();
         this.formData.coverAttachment = ''
         this.file = file.raw
         const url = urlObj.uploadDetailFile;
         let param = new FormData();
         param.append("file", this.file); // 文件
         param.append('bizId','default')
         param.append('module','event')
         param.append('menu','distributor')
         param.append('func','image')
         param.append('category','image')
         param.append('isThumb',true)
         this.$axios.post(url, param, 'file')
             .then((res) => {
               this.postershow = false
               this.formData.coverAttachment = res.records
               this.formData.coverAttachment.poster = window.getResourcesHost()+this.formData.coverAttachment.location+'/'+this.formData.coverAttachment.formatName;
               this.formData.coverAttachment.ossImageUrl = this.formData.coverAttachment.url;
               this.postershow = true
             });
       },

       //删除标签
       handleClose(tag) {
         this.dynamicTags.splice(this.dynamicTags.indexOf(tag), 1);
       },

       //输入标签时
       showInput() {
         this.inputVisible = true;
         this.$nextTick(_ => {
           this.$refs.saveTagInput.$refs.input.focus();
         });
       },

      //添加标签
       handleInputConfirm() {
         let inputValue = this.inputValue;
         if (inputValue) {
           this.dynamicTags.push(inputValue);
         }
         this.inputVisible = false;
         this.inputValue = '';
       }
    },
   }
</script>

<style scoped lang="scss">
   .pre-sale-main{
      display: flex; padding: 20px;
      background: #fff;
      .right-form > .el-row{ margin-bottom: 10px }
      .right-form{
         padding: 0 0 0 40px;
         .tit{ font-size: 18px; margin-bottom: 30px }
         .input-box{
            line-height: 28px; overflow: hidden;
            .name{
               width: 100px; text-align: right; float: left;
               i{ color: #ff0000; font-size: 18px; margin-right: 4px }
            }
            .flex{ display: flex }
            .content{ float: left; margin-left: 15px }
            .radio{ padding: 7px 0 }
            .m-left-right{ margin: 0 10px }
            .img-list{
              margin-top: 10px;
               .el-row{ margin: 10px 10px 0 0 }
            }
            /* 标签 */
            .el-tag + .el-tag {
               margin-left: 10px;
            }
            .button-new-tag {
               margin-left: 10px;
               height: 32px;
               line-height: 30px;
               padding-top: 0;
               padding-bottom: 0;
            }
            .input-new-tag {
               width: 90px;
               margin-left: 10px;
               vertical-align: bottom;
            }
         }
      }
   }
   .img-list img{
     width: 100px;
     height: 100px;
   }
   .textinpt{
     width: 100px;
     position: absolute;
     top: 100px;
     left: 0px;
     border: 1px solid #dedede;
     padding: 3px 0;
     -webkit-border-radius:3px;//适配以webkit为核心的浏览器(chrome、safari等)
     -moz-border-radius:3px;//适配firefox浏览器
     -ms-border-radius:3px;//适配IE浏览器
     -o-border-radius:3px;//适配opera浏览器
     border-radius:3px;
   }
   .textinpt:focus {
       outline: 1px solid #51c9ff;
       background: rgba(3, 16, 28, 0);
       padding: 3px;
       -webkit-border-radius:3px;//适配以webkit为核心的浏览器(chrome、safari等)
       -moz-border-radius:3px;//适配firefox浏览器
       -ms-border-radius:3px;//适配IE浏览器
       -o-border-radius:3px;//适配opera浏览器
       border-radius:3px;
   }
   .delicon{
     position: absolute;
     top: 0;
     right: 0;
     color: red;
     font-weight: 900;
     font-size: 20px;
     cursor: pointer;
     line-height: 10px;
   }
   .poster{
     width: 120px;
     height: 160px;
     border-radius: 10px;
   }
   /deep/.el-form-item .el-form-item{
     margin-bottom: 22px;
   }
   .dialog-bottombox{
     text-align: center;
     padding: 20px;
   }
   .table-item{
     width: 33%;
     float: left;
     text-align: center;
   }
   /deep/.el-dialog__header {
     background: #2d8cf0;
     border-radius: 10px 10px 0 0;
   }
   /deep/.el-dialog__title {
     font-size: 16px;
     color: #fff;
   }
   /deep/ .el-table th.gutter{
     /*
     * 解决element-ui 表格篡位的问题 👇
     */
     display: table-cell!important;
   }
   /deep/.el-dialog{
     position: relative;
     margin: 0 auto 50px;
     background: #FFFFFF;
     border-radius: 10px;
     -webkit-box-sizing: border-box;
     box-sizing: border-box;
     width: 50%;
   }
   /deep/.el-dialog__body{
     padding: 30px 20px;
     color: #606266;
     font-size: 14px;
     word-break: break-all;
     margin: 0 30px;
   }
   /deep/el-table el-table--fit el-table--enable-row-hover el-table--enable-row-transition{
     border-radius: 10px;
     border: 1px solid #c7e0f5;
   }
   /deep/ .el-table--enable-row-transition .el-table__body td{
     text-align: center;
     font-size: 18px;
     font-weight: 900;
   }
   /deep/ .el-table th.is-leaf, .el-table td{
     text-align: center;
     background: #d0e9f5;
   }
   /deep/.el-table--fit{
     border-right: 0;
     border-bottom: 0;
     border: 1px solid #c6e2ef;
     border-radius: 10px;
   }
   /deep/ .cell label>.el-radio__label{
     display: none
   }

</style>
